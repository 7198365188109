import React from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import statementRunService from '../../../../services/api/statement-run/statement-run.service';
import { InternalSelect } from '../../../atoms/internal-select/internal-select';
import { KeyValuePair } from '../../../../types/common.types';
import { useDownloadFile } from '../../../../hooks/global';
import { Button } from '../../../atoms';

type StatementRunStatementsSentReportFormProps = {
  defaultValues: {
    statementRunId: string;
  };
  selectValues: KeyValuePair<string>[];
};

export const StatementRunStatementsSentReportForm: React.FC<StatementRunStatementsSentReportFormProps> =
  ({ defaultValues, selectValues }) => {
    const { statementRunId } = defaultValues;
    const { register, getValues } = useForm({
      defaultValues: { statementRunId },
    });

    const { download, loading } = useDownloadFile({
      apiDefinition: () => {
        const formValues = getValues();
        return statementRunService.downloadStatementRunStatementsCreated(formValues.statementRunId);
      },
      onError: () => toast.error('Could not download report'),
    });

    return (
      <div className="flex bg-gray-100 shadow-lg rounded-sm">
        <div className="flex flex-col justify-start items-start p-4 gap-4 w-1/2">
          <span className="w-full text-xl mb-2">Download Statement Run - Statements</span>

          <InternalSelect
            variableName="statementRunId"
            options={selectValues.map((item) => ({ value: item.value, key: item.key }))}
            register={register}
            className="w-3/4"
          />

          <Button
            isLoading={loading}
            onClick={download}
            className="bg-tertiary hover:bg-tertiary-dark text-white text-base w-auto"
          >
            Download
          </Button>
        </div>
        <div className="w-1/2 bg-gray-200 rounded-sm m-5 p-2">
          <h1>Description</h1>
          <p className="pl-3 text-sm">
            This report return the total statements sent during a statement run.
          </p>
        </div>
      </div>
    );
  };
