import React from 'react';
import toast from 'react-hot-toast';
import { Formik, FormikHelpers } from 'formik';
import DateTimePicker from 'react-datetime-picker';

import { Button } from '../../../atoms';
import { FormikFormSchema } from '../schemas/schema';
import { ReportFilterProps } from '../../../../types';
import { useDownloadFile } from '../../../../hooks/global';
import referralsService from '../../../../services/api/referrals/referrals.service';

export const ReferralsExportForm: React.FC = () => {
  const initialValues: ReportFilterProps = {
    fromDate: null,
    toDate: null,
    filterDateBy: 'CreatedDate',
  };

  const { download, loading } = useDownloadFile({
    apiDefinition: async (params: ReportFilterProps) =>
      referralsService.getAllReferralsContent(params.fromDate, params.toDate),
    onSuccess: () => toast.success('File downloaded successfully!'),
    onError: () => toast.error('Error downloading the file.'),
  });

  const handleSubmission = async (
    values: ReportFilterProps,
    { setSubmitting }: FormikHelpers<ReportFilterProps>,
  ) => {
    try {
      await download(values);
    } catch (error) {
      toast.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      onSubmit={handleSubmission}
      validationSchema={FormikFormSchema}
    >
      {({ handleSubmit, isSubmitting, values, handleReset, setFieldValue, errors, touched }) => {
        const _setFromDate = (e) => {
          setFieldValue('fromDate', e);
        };
        const _setToDate = (e) => {
          setFieldValue('toDate', e);
        };

        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex bg-gray-100 shadow-lg rounded-sm"
          >
            <div className="flex flex-col justify-start items-start p-4 gap-4 w-1/2">
              <span className="w-full text-xl mb-2">Download Sign Up Export</span>
              <label htmlFor="filter-date-from" className="w-4/5 flex flex-col ">
                Filter From
                <DateTimePicker
                  id="filter-date-from"
                  clearIcon={null}
                  format="y-MM-dd"
                  onChange={_setFromDate}
                  value={values.fromDate}
                  disableClock
                />
                {touched.toDate && errors.toDate && (
                  <span className="text-red-500 text-sm mt-1">{errors.fromDate}</span>
                )}
              </label>
              <label htmlFor="filter-date-to" className="w-4/5 flex flex-col">
                Filter To
                <DateTimePicker
                  id="filter-date-to"
                  clearIcon={null}
                  format="y-MM-dd"
                  onChange={_setToDate}
                  value={values.toDate}
                  disableClock
                />
                {touched.fromDate && errors.fromDate && (
                  <span className="text-red-500 text-sm mt-1">{errors.toDate}</span>
                )}
              </label>
              <Button
                isLoading={isSubmitting || loading}
                onClick={handleSubmit}
                className="bg-tertiary hover:bg-tertiary-dark text-white text-base w-auto"
              >
                Download
              </Button>
            </div>
            <div className="w-1/2 bg-gray-200 rounded-sm m-5 p-2">
              <h1>Description</h1>
              <p className="pl-3 text-sm">
                The report lists Total users signed up from a referral, with associated details:{' '}
                <br /> Description, Total.
              </p>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
