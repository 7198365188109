import { Tab } from '@headlessui/react/dist/components/tabs/tabs';
import React, { useState } from 'react';

import {
  CallTotalsReportForm,
  FleetFineTotalsReportForm,
  FleetReportForm,
  GeneralReportForm,
  IndividualFineTotalsReportForm,
  IndividualReportForm,
  InvoiceTotalsReportForm,
  LeadPartnerFineTotalsReportForm,
  PaymentSourcesReportForm,
  ReferralsExportForm,
  TmtCapeTownIdExportForm,
  UnverifiedEmailsReportForm,
  UserProfilesExportForm,
  UsersPhoneNumbersForm,
} from '@project/components';
import { classNames } from '@project/helpers';

import { FinesPerStatementByMunicipalityReportOrganism } from '../fines-per-statement-by-municipality-report-organism/fines-per-statement-by-municipality-report-organism';
import { StatementRunStatementsSentReportOrganism } from '../statement-run-statements-sent-report-organism/statement-run-statements-sent-report-organism';

export enum ReportTypeDescriptionEnum {
  'Unverified Emails' = 0,
  'TMT Cape Town ID Sign Up Export' = 1,
  'Fleet Fine Totals' = 2,
  'Individual Fine Totals' = 3,
  'Lead Partner Fine Totals' = 4,
  'User Profiles' = 5,
  'Statement - Fines by Municipality' = 6,
  'Statement Run - Statements Created' = 7,
  'Referrals Sign Up Export' = 8,
  'General Statistics' = 9,
  'Calls Made' = 10,
  'Invoice Totals' = 11,
  'Payment Totals By Sources' = 12,
  'Phone Numbers WhatsApp Status' = 13,
  'Individual Statistics' = 14,
  'Fleet Statistics' = 15,
}

export enum ReportTypeEnum {
  UnverifiedEmails = 0,
  TmtCapeTownIdExport = 1,
  FleetFineTotals = 2,
  IndividualFineTotals = 3,
  LeadPartnerFineTotals = 4,
  UserExport = 5,
  FinesPerStatementByMunicipality = 6,
  StatementRunStatementsCreated = 7,
  ReferralsSignUpExport = 8,
  GeneralReport = 9,
  CallTotals = 10,
  InvoiceTotals = 11,
  PaymentSources = 12,
  UsersPhoneNumbers = 13,
  IndividualReport = 14,
  FleetReport = 15,
}

export const ReportsOrganism: React.FC = () => {
  const [reportGroups] = useState([
    {
      name: 'General',
      reports: [
        ReportTypeEnum.UnverifiedEmails,
        ReportTypeEnum.TmtCapeTownIdExport,
        ReportTypeEnum.UserExport,
        ReportTypeEnum.GeneralReport,
        ReportTypeEnum.UsersPhoneNumbers,
        ReportTypeEnum.LeadPartnerFineTotals,
      ],
    },
    {
      name: 'Individual Fines',
      reports: [ReportTypeEnum.IndividualFineTotals, ReportTypeEnum.IndividualReport],
    },
    {
      name: 'Fleet Fines',
      reports: [ReportTypeEnum.FleetFineTotals, ReportTypeEnum.FleetReport],
    },
    {
      name: 'Invoicing',
      reports: [ReportTypeEnum.PaymentSources],
    },
    {
      name: 'Statements',
      reports: [
        ReportTypeEnum.FinesPerStatementByMunicipality,
        ReportTypeEnum.StatementRunStatementsCreated,
      ],
    },
    {
      name: 'Call Centre',
      reports: [ReportTypeEnum.CallTotals, ReportTypeEnum.InvoiceTotals],
    },
    {
      name: 'Referrals',
      reports: [ReportTypeEnum.ReferralsSignUpExport],
    },
  ]);

  const renderSelectedReport = (report: ReportTypeEnum): JSX.Element => {
    switch (report) {
      case ReportTypeEnum.UnverifiedEmails:
        return <UnverifiedEmailsReportForm />;
      case ReportTypeEnum.TmtCapeTownIdExport:
        return <TmtCapeTownIdExportForm />;
      case ReportTypeEnum.UserExport:
        return <UserProfilesExportForm />;
      case ReportTypeEnum.IndividualFineTotals:
        return <IndividualFineTotalsReportForm />;
      case ReportTypeEnum.FleetFineTotals:
        return <FleetFineTotalsReportForm />;
      case ReportTypeEnum.LeadPartnerFineTotals:
        return <LeadPartnerFineTotalsReportForm />;
      case ReportTypeEnum.FinesPerStatementByMunicipality:
        return <FinesPerStatementByMunicipalityReportOrganism />;
      case ReportTypeEnum.StatementRunStatementsCreated:
        return <StatementRunStatementsSentReportOrganism />;
      case ReportTypeEnum.ReferralsSignUpExport:
        return <ReferralsExportForm />;
      case ReportTypeEnum.GeneralReport:
        return <GeneralReportForm />;
      case ReportTypeEnum.CallTotals:
        return <CallTotalsReportForm />;
      case ReportTypeEnum.InvoiceTotals:
        return <InvoiceTotalsReportForm />;
      case ReportTypeEnum.PaymentSources:
        return <PaymentSourcesReportForm />;
      case ReportTypeEnum.UsersPhoneNumbers:
        return <UsersPhoneNumbersForm />;
      case ReportTypeEnum.IndividualReport:
        return <IndividualReportForm />;
      case ReportTypeEnum.FleetReport:
        return <FleetReportForm />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex-grow pb-10">
      <Tab.Group vertical>
        <div className="flex gap-4">
          <div className="w-1/3 shrink-0">
            <Tab.List className="flex flex-col space-y-2">
              {reportGroups.map((reportGroup) => (
                <div key={reportGroup.name} className="space-y-1">
                  <div className="px-2 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded">
                    {reportGroup.name}
                  </div>
                  <div className="space-y-1 ml-5">
                    {reportGroup.reports.map((report) => (
                      <Tab
                        key={report}
                        className={({ selected }) =>
                          classNames(
                            'w-full px-3 py-2 text-sm font-medium text-left rounded-lg transition-colors',
                            'focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500',
                            selected
                              ? 'bg-blue-600 text-white shadow'
                              : 'text-gray-600 hover:bg-gray-100',
                          )
                        }
                      >
                        {ReportTypeDescriptionEnum[report]}
                      </Tab>
                    ))}
                  </div>
                </div>
              ))}
            </Tab.List>
          </div>
          <div className="flex-grow w-1/2 min-w-0">
            <Tab.Panels>
              {reportGroups
                .flatMap((reportGroup) => reportGroup.reports)
                .map((report) => (
                  <Tab.Panel
                    key={report}
                    className={classNames(
                      'rounded-xl bg-white',
                      'focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500',
                    )}
                  >
                    {renderSelectedReport(report)}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </div>
  );
};
