import config from '@project/config';

const reportsBaseUrl = `${config.apiUrl(1)}/Reports`;

export default {
  leadPartnersUrl: (): string => `${reportsBaseUrl}/Users/Lead-Partners`,
  generalReportUrl: (): string => `${reportsBaseUrl}/General`,
  individualReportUrl: (): string => `${reportsBaseUrl}/Individual`,
  fleetReportUrl: (): string => `${reportsBaseUrl}/Fleet`,
  paymentSourcesReportUrl: (): string => `${reportsBaseUrl}/PaymentEvents`,
};
