import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { useQueryParam } from 'use-query-params';
import { ArrowBack } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useMutation } from 'react-query';

import { SignUpV2Form } from './sign-up-v2-form';
import { IconCard } from '../../molecules';
import { OnboardBusinessProps, OnboardIndividualProps, SignUpProps } from '../../../types';
import { userAuthService } from '../../../services';
import { OnboardIndividualV2 } from '../onboard/onboard-individual-v2';
import { OnboardBusinessV2 } from '../onboard/onboard-business-v2';
import signUpAttemptService from '../../../services/api/sign-up-attempt/sign-up-attempt.service';
import { ProfileTypeEnum } from '../../../enums/profile-type.enum';
import { AuthMethodEnum } from '../../../enums';

type OnboardStep = 'account' | 'individual' | 'business' | 'step-selection';

type StepInfo = {
  currentStep: OnboardStep;
  stepSequence: OnboardStep[];
};

export type SignUpV2FormValues = {
  individual: OnboardIndividualProps;
  business: OnboardBusinessProps;
  account: SignUpProps;
};

export const SignUpProcess = ({
  setSignUpType,
}: {
  setSignUpType: (value: ProfileTypeEnum) => void;
}) => {
  const { values, errors, isSubmitting, setSubmitting, setFieldError } =
    useFormikContext<SignUpV2FormValues>();
  const history = useHistory();
  const [utmSource] = useQueryParam<string>('utm_source');
  const [utmMedium] = useQueryParam<string>('utm_medium');
  const [utmCampaign] = useQueryParam<string>('utm_campaign');
  const [utmContent] = useQueryParam<string>('utm_content');
  const [utmTerm] = useQueryParam<string>('utm_term');
  const [referrerEmail] = useQueryParam<string>('referrer_email');
  const [referralEmail] = useQueryParam<string>('referral_email');
  const gclidCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)_gcl_aw\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  const gclid =
    gclidCookie && gclidCookie !== ''
      ? gclidCookie.substring(gclidCookie.lastIndexOf('.') + 1)
      : null;

  const [stepInfo, setStepInfo] = useState<StepInfo>({
    currentStep: 'account',
    stepSequence: ['account', 'step-selection'],
  });

  const _nextStep = () => {
    const currentStepIndex = stepInfo.stepSequence.indexOf(stepInfo.currentStep);
    if (currentStepIndex === stepInfo.stepSequence.length - 1) {
      if (Object.keys(errors).length > 0) return;
      setSubmitting(true);

      switch (values.account.authMethod) {
        case AuthMethodEnum.Internal:
          userAuthService
            .signUpv2(
              values.account,
              values.individual,
              values.business,
              {
                utmCampaign,
                utmMedium,
                utmSource,
                utmContent,
                utmTerm,
              },
              {
                referrerEmail,
                referralEmail,
              },
            )
            .then(() => {
              history.replace('/sign-up-success');
            })
            .catch((e) => {
              setSubmitting(false);
              toast.error(e.reason);
            });
          break;
        case AuthMethodEnum.Facebook:
          break;
        case AuthMethodEnum.Apple:
          break;
        default:
          break;
      }
    } else {
      setStepInfo({
        stepSequence: [...stepInfo.stepSequence],
        currentStep: stepInfo.stepSequence[currentStepIndex + 1],
      });
    }
  };

  const _previousStep = () => {
    const currentStepIndex = stepInfo.stepSequence.indexOf(stepInfo.currentStep);
    setStepInfo({
      stepSequence: [...stepInfo.stepSequence],
      currentStep: stepInfo.stepSequence[currentStepIndex - 1],
    });
  };

  const _accountSubmit = async () => {
    const { email, authMethod } = values.account;
    setSubmitting(true);

    if (authMethod === AuthMethodEnum.Internal) {
      try {
        const result = await userAuthService.canSignUp(email);

        if (result.canSignUp) {
          _nextStep();
        } else {
          toast.error(
            result.reason || 'Email cannot be used for signup. Please try a different email.',
          );
          setFieldError('account.email', result.reason);
        }
      } catch (error) {
        toast.error('Email already exists. Please log in or try a different email');
        setFieldError('account.email', error.detail);
      } finally {
        setSubmitting(false);
      }
    } else {
      signUpAttemptService
        .create(utmSource, utmMedium, utmCampaign, utmContent, utmTerm, gclid)
        .then(() => {
          setSubmitting(false);
          _nextStep();
        })
        .catch(() => {
          setSubmitting(false);
          _nextStep();
        });
    }
  };

  const createSignUpAttempt = useMutation(
    () =>
      signUpAttemptService.create(
        values.account.email,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
        gclid,
      ),
    {
      onError: () => {
        toast.error('Failed to create signup attempt');
      },
    },
  );

  useEffect(() => {
    if (
      (values.account?.email?.length ?? 0) > 0 &&
      !errors.account?.email &&
      !errors.account?.confirmEmail &&
      utmSource !== null
    ) {
      createSignUpAttempt.mutate();
    }
  }, [errors.account?.confirmEmail]);

  return (
    <>
      <Toaster />
      {stepInfo.currentStep !== 'account' && (
        <IconButton onClick={_previousStep}>
          <ArrowBack />
        </IconButton>
      )}
      {stepInfo.currentStep === 'account' && (
        <SignUpV2Form
          isLoading={isSubmitting}
          submitForm={_accountSubmit}
          onSuccess={() => null} // move to next form
          onFailure={() => null}
        />
      )}

      {stepInfo.currentStep === 'individual' && <OnboardIndividualV2 submitForm={_nextStep} />}

      {stepInfo.currentStep === 'business' && <OnboardBusinessV2 submitForm={_nextStep} />}

      {stepInfo.currentStep === 'step-selection' && (
        <div className="flex gap-4 my-4 w-full justify-center items-center flex-col flex-1">
          <p className="text-text-grey text-xl">Profile Sign Up</p>
          <IconCard
            icon={<img src="assets/icons/user-filled-icon.png" alt="user" />}
            text="Individual Profile"
            onClick={() => {
              setSignUpType(ProfileTypeEnum.individual);
              return setStepInfo({
                stepSequence: ['account', 'step-selection', 'individual'],
                currentStep: 'individual',
              });
            }}
            className="bg-secondary w-3/5 hover:bg-secondary-dark"
          />
          <IconCard
            icon={<img src="assets/icons/truck-filled-icon.png" alt="business" />}
            text="Business Profile"
            onClick={() => {
              setSignUpType(ProfileTypeEnum.business);
              return setStepInfo({
                stepSequence: ['account', 'step-selection', 'business'],
                currentStep: 'business',
              });
            }}
            className="w-3/5 hover:bg-primary-dark"
          />

          <IconCard
            icon={<img src="assets/icons/truck-user-filled-icon.png" alt="business" />}
            text="Individual and Business"
            onClick={() => {
              setSignUpType(ProfileTypeEnum.both);
              return setStepInfo({
                stepSequence: ['account', 'step-selection', 'individual', 'business'],
                currentStep: 'individual',
              });
            }}
            className="bg-tertiary w-3/5 hover:bg-tertiary-dark"
          />
        </div>
      )}
    </>
  );
};
