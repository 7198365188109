import React from 'react';
import toast from 'react-hot-toast';

import userService from '../../../../services/api/user/user.service';
import { useDownloadFile } from '../../../../hooks/global';
import { Button } from '../../../atoms';

export const UserProfilesExportForm: React.FC = () => {
  const { download, loading } = useDownloadFile({
    apiDefinition: () => userService.getUserProfiles(),
    onError: () => toast.error('Could not download report'),
  });

  return (
    <div className="flex bg-gray-100 shadow-lg rounded-sm">
      <div className="flex flex-col justify-start items-start p-4 gap-4 w-1/2">
        <span className="w-full text-xl mb-2">Download User Profiles</span>
        <Button
          isLoading={loading}
          onClick={download}
          className="bg-tertiary hover:bg-tertiary-dark text-white text-base w-auto"
        >
          Download
        </Button>
      </div>
      <div className="w-1/2 bg-gray-200 rounded-sm m-5 p-2">
        <h1>Description</h1>
        <p className="pl-3 text-sm">
          The report lists all users on the system, with associated details: <br /> Identifier,
          Email, Profile Type.
        </p>
      </div>
    </div>
  );
};
