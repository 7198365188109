import React from 'react';
import { useQuery } from 'react-query';

import statementRunService from '../../../services/api/statement-run/statement-run.service';
import { StatementRunStatementsSentReportForm } from '../../forms';

export const StatementRunStatementsSentReportOrganism: React.FC = () => {
  const { isFetching, data } = useQuery('statementRuns', statementRunService.get, {
    refetchOnWindowFocus: false,
  });

  const sortData = data?.sort((a, b) =>
    a.startedDate < b.startedDate ? 1 : a.startedDate > b.startedDate ? -1 : 0,
  );

  return isFetching ? null : (
    <StatementRunStatementsSentReportForm
      defaultValues={{
        statementRunId: sortData[0].id,
      }}
      selectValues={sortData.map((statementRun) => {
        return {
          value: `${statementRun.name} (${statementRun.startedDate})`,
          key: statementRun.id,
        };
      })}
    />
  );
};
