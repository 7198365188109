import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactQueryDevtools } from 'react-query/devtools';

import { NotFound } from '../screens/global';
import {
  ForgotPasswordScreen,
  ForgotPasswordOtpScreen,
  LoginScreen,
  ResetPasswordScreen,
  UnlockAccountScreen,
  SignUpScreen,
  OnboardIndividualScreen,
  OnboardBusinessScreen,
  OnboardScreen,
  IndividualScreen,
  LandingScreen,
  IndividualBasketScreen,
  BusinessScreen,
  BusinessBasketScreen,
  InitialPasswordScreen,
  VerifyNewEmailScreen,
  AdminScreen,
  FinesScreen,
  InvoicesScreen,
  UsersScreen,
  AccountScreen,
  IndividualFineScreen,
  IndividualInvoiceScreen,
  AdminInvitationScreen,
  ReportsScreen,
  CallCentreScreen,
  TmtFinesBulkUploadScreen,
  WhatsAppWhitelistBulkUploadScreen,
  DeleteInstructionsScreen,
  OtpScreen,
} from '../screens';
import { AppLayout, AuthLayout, AdminLayout, ProtectedRoute, RouteWrapper } from '../components';
import SignUpSuccessScreen from '../screens/auth/sign-up-success/sign-up-success.screen';
import VerifyAccountScreen from '../screens/auth/verify-account/verify-account.screen';
import AdminLandingScreen from '../screens/app/landing/adminLanding.screen';
import RedirectToPayFast from '../screens/global/RedirectToPayFast/redirect-to-payfast.screen';
import ViewInvoiceScreen from '../screens/global/view-invoice/view-invoice.screen';
import AnonymousAccessLayout from '../components/containers/anonymous-access-layout/anonymous-access-layout';
import WhatsAppOptOutScreen from '../screens/app/whatsapp-opt-out/whatsapp-opt-out.screen';
import EmailOptOut from '../screens/app/email-opt-out/email-opt-out.screen';
import ReferralSuccessScreen from '../screens/app/referral-success/referral-success.screen';
import AdminPaymentPartnersPaymentsScreen from '../screens/app/admin-payment-partners/admin-payment-partners-payments.screen';
import AdminPaymentPartnersFinesScreen from '../screens/app/admin-payment-partners-fines/admin-payment-partners-fines.screen';
import AccountDeleteSuccessScreen from '../screens/app/account-delete-success/account-delete-success';
import WhatsAppOptInScreen from '../screens/app/whatsapp-opt-in/whatsapp-opt-in.screen';
import Callback from '../components/callback';
import { BlankAccessLayout } from '../components/containers/blank-access-layout/blank-access-layout';
import AdminBlackListScreen from '../screens/app/admin-black-list/admin-black-list.screen';
import PayFastEmailUploadScreen from '../screens/app/admin-payfast-email-upload/admin-payfast-email-upload.screen';

const RootRouter: FC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ReactQueryDevtools initialIsOpen />
        <Switch>
          {/* AUTH */}
          <RouteWrapper path="/login" exact component={LoginScreen} layout={AuthLayout} />
          <RouteWrapper path="/callback" exact component={Callback} />
          <RouteWrapper path="/sign-up" exact component={SignUpScreen} layout={AuthLayout} />
          <RouteWrapper
            path="/forgot-password"
            exact
            component={ForgotPasswordScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/delete-instructions"
            exact
            component={DeleteInstructionsScreen}
            layout={BlankAccessLayout}
          />
          <RouteWrapper
            path="/forgot-password-otp"
            exact
            component={ForgotPasswordOtpScreen}
            layout={AuthLayout}
          />
          <RouteWrapper path="/otp-confirm" exact component={OtpScreen} layout={AuthLayout} />
          <RouteWrapper
            path="/reset-password"
            exact
            component={ResetPasswordScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/initial-password"
            exact
            component={InitialPasswordScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/unlock-account"
            exact
            component={UnlockAccountScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/sign-up-success"
            exact
            component={SignUpSuccessScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/account-delete-success"
            exact
            component={AccountDeleteSuccessScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/referral-success"
            exact
            component={ReferralSuccessScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/verify-account"
            exact
            component={VerifyAccountScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/verify-new-email"
            exact
            component={VerifyNewEmailScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/redirect-gateway-payment"
            exact
            component={RedirectToPayFast}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/whatsapp-opt-out"
            exact
            component={WhatsAppOptOutScreen}
            layout={AuthLayout}
          />
          <RouteWrapper
            path="/whatsapp-opt-in"
            exact
            component={WhatsAppOptInScreen}
            layout={AuthLayout}
          />
          <RouteWrapper path="/email-opt-out" exact component={EmailOptOut} layout={AuthLayout} />
          <RouteWrapper
            path="/invoice/:id"
            exact
            component={ViewInvoiceScreen}
            layout={AnonymousAccessLayout}
          />
          <ProtectedRoute
            path="/individual"
            exact
            component={IndividualScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/business"
            exact
            component={BusinessScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/individual-basket"
            exact
            component={IndividualBasketScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/business-basket"
            exact
            component={BusinessBasketScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/onboard"
            exact
            component={OnboardScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/onboard-individual"
            exact
            component={OnboardIndividualScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/onboard-business"
            exact
            component={OnboardBusinessScreen}
            layout={AppLayout}
            roles={['PortalUser']}
          />
          <ProtectedRoute
            path="/"
            exact
            component={LandingScreen}
            layout={AppLayout}
            roles={[
              'PortalUser',
              'Consultant',
              'SuperAdministrator',
              'CallCenterAgent',
              'CallCenterManager',
            ]}
          />
          {/* Admin */}
          <ProtectedRoute
            path="/admin"
            exact
            component={AdminScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/fines"
            exact
            component={FinesScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/tmt-fines-bulk-upload"
            exact
            component={TmtFinesBulkUploadScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/payfast-email-upload"
            exact
            component={PayFastEmailUploadScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/whatsapp-whitelist-bulk-upload"
            exact
            component={WhatsAppWhitelistBulkUploadScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/invoice"
            exact
            component={InvoicesScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/individual/user"
            exact
            component={AccountScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/users"
            exact
            component={UsersScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/individual/fine"
            exact
            component={IndividualFineScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/individual/invoice"
            exact
            component={IndividualInvoiceScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/consultant"
            exact
            component={AdminInvitationScreen}
            layout={AdminLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/payment-partners/payments"
            component={AdminPaymentPartnersPaymentsScreen}
            layout={AdminLayout}
            roles={['SuperAdministrator']}
          />
          <ProtectedRoute
            path="/admin/payment-partners"
            exact
            component={AdminPaymentPartnersFinesScreen}
            layout={AdminLayout}
            roles={['SuperAdministrator']}
          />
          <ProtectedRoute
            path="/admin/reports"
            exact
            component={ReportsScreen}
            layout={AdminLayout}
            roles={['SuperAdministrator', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/black-list"
            exact
            component={AdminBlackListScreen}
            layout={AdminLayout}
            roles={['SuperAdministrator', 'CallCenterManager']}
          />
          <ProtectedRoute
            path="/admin/call-centre"
            exact
            component={CallCentreScreen}
            layout={AdminLayout}
            roles={['SuperAdministrator', 'CallCenterManager', 'CallCenterAgent']}
          />
          <ProtectedRoute
            path="/"
            exact
            component={AdminLandingScreen}
            layout={AppLayout}
            roles={['Consultant', 'SuperAdministrator', 'CallCenterAgent', 'CallCenterManager']}
          />
          )
          <Route path="*" component={NotFound} />
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default RootRouter;
